// prettier-ignore
export default {
  sign_in: "Sign in",
  sign_in_to_start_your_session: "Sign in to start your session",
  remember_me: "Remember me",
  i_forgot_my_password: "I forgot my password",
  register_a_new_membership: "Register a new membership",
  invalid_login_or_password: "Invalid Login or Password",
  all: "All",
  connection: "Connection",
  connections: "Connections",
  connection_status: "Connection Status",
  connected: "Connected",
  disconnected: "Disconnected",
  disconnection: "Disconnection",
  alarm: "Alarm",
  alarms: "Alarms",
  active: "Active",
  inactive: "Inactive",
  description: "Description",
  s_n: "S/N",
  status: "Status",
  alarm_active: "Activated Alarm",
  alarm_inactive: "Alarm Inactive",
  no_equipment_was_found: "No equipment was found",
  equipment_list: "Equipment list",
  sign_out: "Sign out",
  profile: "Profile",
  hours_alarms: "Hours alarms",
  hours_alarms_24: "24 Hours alarms",
  days_runtime: "Days Runtime",
  perc_days_runtime_30: "Days Runtime",
  next_maintenance: "Next Maintenance",
  days_next_maintenance: "Days to next maitenance",
  not_latest: "Não recente",
  recent: "Recent",
  model: "Model",
  instance: "Instance|Instances",
  equipment: "Equipment",
  equipment_not_selected: "Equipment not selected",
  equipment_alarms: "Equipment Alarms",
  n: "Nº",
  criteria: "Criteria",
  timestamp: "Timestamp",
  value: "Value",
  reference: "Reference",
  level: "Level",
  there_are_no_alarms_configuration: "There are no alarms configured or that match the selection criteria",
  list_can_not_be_displayed_in_editor_mode: "List can not be displayed while in edit mode",
  there_are_no_alarms_in_the_selected_range: "There are no alarms in the selected range",
  data_history: "Data history",
  main_navigation: "MAIN NAVIGATION",
  home: "home",
  support: "support",
  last_event: "Last event",
  no_data_found: "No data found",
  customer: "Customer",
  state: "State",
  city: "City",
  supervisor: "Supervisor",
  region: "Region",
  calendar: {
    Su: "Su",
    Mo: "Mo",
    Tu: "Tu",
    We: "We",
    Th: "Th",
    Fr: "Fr",
    Sa: "Sa",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    today: "Today",
    yesterday: "Yesterday",
    last_24_hours: "Last 24 Hours",
    last_7_days: "Last 7 Days",
    last_30_days: "Last 30 Days",
    this_month: "This Month",
    last_month: "Last Month",
    input: "Input",
    button: "Button",
    interval_range: "Interval Range"
  },
  apply: "Apply",
  apply_to: "Apply to",
  Cancel: "Cancel",
  ok: 'Ok',
  From: "From",
  To: "To",
  Custom: "Custom",
  edit: "Edit",
  there_are_no_data_configured: "There are no data configured",
  there_are_no_items_configured: "There are no items configured",
  unit: "Unit",
  name: "Name",
  options: "Options",
  show_lines: "Show lines",
  do_not_show_lines: "Don't show lines",
  there_are_no_data_to_show: "There are no data to show",
  data_selector: "Data selector",
  data_configuration: "Data configuration",
  data_editor: "Data Editor",
  config: "Config",
  save: "Save",
  saving: 'Saving',
  you_have_saved_n_items: `You have saved {count} item!`,
  you_have_deleted_n_items: `You have deleted {count} item!`,
  item_could_not_be_saved: "Item could not be saved!",
  item_could_not_be_deleted: "Item could not be deleted!",
  invalid_resources: 'Invalid resources',
  saved: "Saved",
  deleted: "Deleted",
  please_assign_the_title_of_the_property: "Please assign the title of the property",
  component_not_found: "Component not found",
  invalid_component: "Invalid Component",
  vue_component: "Vue Component",
  no_user_rules_were_found: "No user rules were found",
  unauthorized: "Unauthorized",
  you_do_not_have_access_to_the_requested_resource: "You do not have access to the requested resource!",
  out_of_range: "Out of range",
  data_required: "Required data",
  previous_value: "Previous value",
  confirm: "Confirm",
  confirmed: "Confirmed",
  canceled: "Canceled",
  execution: "Execution",
  verification: "Verification",
  acknowledgement: "Acknowledgement",
  acknowledge: "Acknowledge",
  validate: "Validate",
  validating: "Validating",
  remark: "Remark",
  group: "Group | Groups",
  permission_group: "Permission Group | Permission Groups",
  new_group: "New group",
  select_a_group: "Select a group",
  create_a_new_group: "Create a new group",
  you_dont_have_any_group_yet: "You don't have any group yet!",
  send_notifications_to: "Send notifications to",
  users_to_notify: "Users to be notified",
  emails: "E-mails",
  not_selected: "Not selected",
  priority: "Priority",
  equipment_notifications: "Equipment notifications",
  alarm_notifications: "Alarm notifications",
  wait_for_alarm_recognition_timeout: "Wait for alarm recognition timeout",
  before_try_a_new_group: "Before try a new group",
  notification_configuration: "Notification Configuration",
  invalid_data: "Invalid Data",
  invalid_action: "Invalid action",
  invalid_serial_number: "Invalid serial number",
  invalid_image: "Invalid Image",
  invalid_name: "Invalid Name",
  user: "User | Users",
  user_list: "User list",
  username: "Username",
  full_name: "Full name",
  first_name: "First name",
  last_name: "Last name",
  password: "Password",
  confirm_password: "Password confirmation",
  require_password_change: "Require password change",
  roles: "Roles (control access)",
  access_control: "Access Control",
  set_new_password: "Set new password",
  notification: "Notification | Notifications",
  email: "E-mail",
  cancel: "Cancel",
  not_found: "Not found",
  delete: "Delete",
  clone: "Clone",
  a_clone: 'Clone',
  save_as: "Save as",
  create_copy: "Create copy",
  serial_number: "Serial Number",
  serial_number_failover: "Replacement connector S/N",
  extended_properties: "Extended Properties",
  acknowledgement_execution_requested: "Acknowledgement execution requested",
  unknown_error_while_executing_request: "Unknown error while executing request",
  invalid_equipment_serial_number: "Invalid equipment serial number",
  new: "New",
  search: "Search",
  are_you_sure: "Are you sure?",
  you_wont_be_able_to_revert_this: "You won't be able to revert this",
  yes_delete_it: "Yes, delete it",
  yes_proceed: "Yes, proceed",
  plc: "PLC",
  new_equipment: "New equipment",
  alarmed: "Alarmed",
  normal: "Normal",
  ack: "Ack",
  not: "Not",
  alarm_acknowledgement: "Alarm Acknowledgement",
  subevent: "Subevent",
  ack_required: "Waiting_ack",
  processing: "Processing",
  no_ack_required: "Acknowledment not required",
  acknowledged_alarm: "Acknowledged Alarm",
  item_not_saved: "Item not saved",
  process_area: "Process Area",
  process_areas: "Process Areas",
  select_the_process_area: "Select the process area",
  privileges: "Privileges",
  process_area_name: "Process area name",
  process_area_description: "Process area description",
  edit_this_item: "Edit this item",
  remove: "Remove",
  remove_permanently: "Remove permanently|Removed permanently",
  removing: "Removing",
  remove_this_item: "Remove this item",
  not_allowed: "Not allowed",
  you_do_not_have_enough_privileges_to_execute_this_action: "You do not have enough privileges to execute this action",
  new_process_area: "New process area",
  user_profile: "User Profile",
  previous_password: "Previous password",
  new_password: "New Password",
  this_operation_might_be_affecting_other_equipment: "This operation be affecting other equipment",
  activating_please_wait: "Activating please wait",
  user_could_not_be_activated: "User could not be activated",
  alarm_configuration: "Alarm Configuration",
  limit: "Limit",
  acknowledgement_enabled: "Acknowledgement enabled",
  yes: "Yes",
  no: "No",
  enabled: "Enabled",
  disabled: "Disabled",
  map: 'Map',
  connected_devices: "Connected Devices",
  for: "for | for",
  filter: "Filter | Filters",
  device: "Device | Devices",
  connector: "Connector | Connectors",
  connector_status: "Connector Status",
  device_status: "Device Status",
  address: "Address",
  address_example: "Av. Paulista, 1000 - São Paulo - SP",
  type_the_full_address_and_click_search_button: "Type the full address and click search button",
  neighborhood: "Neighborhood",
  coordinates: "Coordinates",
  country: "Country",
  protocol: "Protocol",
  datetime_update: "Datetime Update",
  optimized: "Optimized",
  ip_network_configuration: "IP network configuration",
  copy: "Copy {item}",
  paste: "paste",
  cut: "cut",
  timezone: "Timezone",
  location: "Location",
  location_placeholder: "Warehouse 7",
  location_name: 'Location',
  location_address_city: 'Address: City',
  location_address_state: 'Address: State',
  location_address_latlng: 'Address: Coordinates',
  location_address_number: 'Address: Number',
  location_address_street: 'Address: Street',
  location_address_country: 'Address: Country',
  location_address_zipcode: 'Address: Zip Code',
  location_address_district: 'Address: District',
  location_address_extrainfo: 'Address: Complement',
  is_connected: 'Is connected?',
  number_active_alarms: 'Number of active alarms',
  has_active_alarms: "Has active alarms?",
  type: "Type",
  connector_model: "Connector Model",
  duplication: "Duplication",
  new_connector: "New Connector",
  new_device: "New Device",
  new_data: "New Data",
  new_alarm: "New Alarm",
  is_hi_device: "Is HI Device",
  device_address: "Device Address",
  local_storage_enabled: "Local storage enabled",
  local_storage_initial_address: "Local storage initial address",
  local_storage_version_id: "Local storage version id",
  has_the_same_process_area_as_its: {
    connector: "Has the same process area as its connector?",
    device: "Has the same process area as its device?",
    data: "Has the same process area as its data?",
  },
  replace_it: "Replace",
  loading: "Loading",
  updating: "Updating",
  connector_list: "Connector list",
  device_list: "Device list",
  data_list: "Data list",
  alarm_list: "Alarm list",
  screen_list: "Screen List",
  device_name: "Device Name",
  create_standard_device: "Create standard device",
  data: "Data",
  data_name: "Data name",
  memory_type: "Memory data type",
  memory_address: "Memory address",
  memory_size: "Memory size",
  value_source: "Data source",
  identity_number: "Identity number",
  data_identification: "Data identification",
  configurations: "Configurations",
  read_only: "Read only?",
  history: "History?",
  format: "Format",
  calculation: "Calculation",
  none: "None",
  parameter: "Parameter",
  result: "Resultado",
  data_calculation_1: "A * Value",
  data_calculation_2: "A * Value + B",
  data_calculation_4: "(Value & A) >> B",
  copying: "Copying",
  deleting: "Deleting",
  requires_acknowledgement: "Requires acknowledgement",
  value_of: "Value of",
  trigger_criteria: "Trigger Criteria",
  condition: "Condition",
  trigger_condition: "Trigger condition|Trigger conditions",
  triggered_action: "Triggered action|Triggered actions",
  new_trigger_condition: "Create trigger condition",
  alarm_name: "Alarm name",
  connector_name: "Connector Name",
  connector_plural: "Connectors",
  device_plural: "Devices",
  data_plural: "Data",
  alarm_plural: "Alarms",
  resource: "Resource",
  resources: "Resources",
  you_have_reached_your_plan_limit: "You have reached your plan limit",
  limit_exceeded: "Limit exceeded",
  generic_notification: "Generic notifications",
  users_to_be_notified_by_email: "Users to be notified by email",
  users_to_be_notified_by_telegram: "Users to be notified by Telegram",
  create_new_token: "Create new token",
  edit_token: "Edit token",
  configured_screen_file_not_found: "Configured screen file not found",
  attention: "Attention",
  add_new_connector: "Add a new connector",
  add_new_device: "Add a new device",
  add_new_data: "Add a new data",
  add_new_alarm: "Add a new alarm",
  text_list: "Text List",
  new_text_list: "New text list",
  new_text_value: "New text value",
  text: "String",
  set_as_default: "Set as default",
  text_list_name: "Text list name",
  close: "Close",
  closed: "Closed",
  already_have_an_account: "Already Have An account? Login here!",
  create_account: "Create account",
  please_fill_this_form_to_create_an_account: "Please fill this form to create an account.",
  i_accept_the: "I accept the",
  terms_of_use: "Terms of use",
  privacy_policy: "Privacy policy",
  signup_success_message: "Your account has been created and an email has been sent.\nBefore you login you must validate your account opening your email and activating the account",
  activation_success_message: "Your account is now active and should be able to login now",
  congratulation: "Congrats!",
  unknown_error: "Erro desconhecido",
  sorry_we_could_not_perform_your_request: "Sorry, we could not perform your request",
  an_error_has_occurred: "An error has occurred",
  user_group: "User group",
  permissions: "Permissions",
  access_permissions: "Access permissions",
  save_and_exit: "Save and exit",
  save_and_continue: "Save and continue",
  save_and_add_new: "Save and add new",
  feature_not_available: "Feature not available",
  resend_the_activation_code: "Resend the activation code",
  please_fill_this_form_to_require_a_new_activation_code: "Please fill this form to require a new activation code",
  please_fill_this_form_to_require_password_reset: "Please fill this form to receive the access link to the password reset form",
  please_fill_this_form_to_reset_your_password: "Please fill this form to reset your password",
  please_fill_out_this_form_to_activate_your_account: "Please fill out this form to activate your account",
  activation_code_request_success: "An new activation code has been requested and an email has been sent.\nBefore you login you must validate your account opening your email and activating the account",
  password_reset_request_success: "An email with detailed instructions was just sent.",
  password_reset_success: "Your password has been changed.\nYou should be able to login now",
  done: "Done!",
  back: "Back",
  reset: "Reset",
  download: "Download",
  download_historical_records_of: "download_historical_records_of {what}",
  invalid_profile: "Invalid User Profile",
  the_specified_user_does_not_have_a_valid_profile: "The specified user does not have a valid profile",
  maximum_connectors: "maximum connectors",
  registered_connectors: "registered connectors",
  maximum_third_party_connectors: "maximum third party connectors",
  registered_third_party_connectors: "registered third party connectors",
  maximum_data: "maximum data",
  registered_data: "registered data",
  maximum_alarms: "maximum alarms",
  registered_alarms: "registered alarms",
  maximum_histories: "maximum histories",
  registered_histories: "registered histories",
  allowed_remote_access: "allowed remote access",
  allowed_telegram_integration: "allowed telegram integration",
  allowed_api_rest: "allowed api rest",
  plan: "Plan",
  my_plan: "My plan",
  current_plan: "Current Plan",
  current_montly_fee: "Current Monthly Fee",
  current_screen: "Current Screen",
  total_usage: "Total Usage",
  simulated_plan: "Simulated plan",
  payment_overview: "Payment overview",
  upgrade: "Upgrade",
  amount_to_be_paid: "Amount to be paid",
  balance_amount: "Balance amount",
  minimum_value: "Minimum value",
  base_value: "Base value",
  monthly_fee: "Current contract monthly fee",
  upgrade_plan: "Upgrade Plan",
  third_party_connectors: "Third party connectors",
  third_party_devices: "Third party devices",
  remote_access: "Remote access via <small><a href='https://www.hitecnologia.com.br/produtos/software/conectividade/plug-telemetria-acesso-remoto-via-portal-de-telemetria/' target=_blank>Plug Telemetria</a></small>",
  telegram_integration: "Telegram integration",
  api_rest: "REST API",
  transaction_history: "Transaction history",
  contract_status: "Contract status",
  date: "Date",
  time: "Time",
  datetime: "Date and time",
  yes_im_sure: "Yes, I'm sure.",
  do_you_really_want_to_upgrade_your_current_plan: "Do you really want to upgrade your current plan?",
  do_you_really_want_to_downgrade_your_current_plan_to_free: "By regressing to the FREE plan, you will lose access to exclusive resources from paid plans. These records will be permanently removed without recovery. Do you want to downgrade your current plan anyway?",
  do_you_want_to_update_your_plan: "Do you want to update your plan?",
  plan_update: "Plan update",
  plan_update_warning: "Only features that do not change the plan are allowed on this screen",
  geolocation: "Geolocation",
  completed: "Completed!",
  your_plan_was_updated: "Your plan was updated",
  your_plan_could_not_be_updated: "Your plan could not be updated",
  current_contract: "Current Contract",
  contract: "Contract",
  contract_name: "Contract Name",
  contract_name_hint: "Set the contract name to find it on the contract list menu option.",
  contract_description: "Contract Description",
  plan_value_fee: "Plan price",
  message: "Message",
  system_event: "System Event",
  event: 'Event',
  system_sub_event: "Sub Event",
  contract_value: "Contract value",
  transactions: "Transactions",
  contract_resources: "Contract resources",
  or: "or",
  standard_device: "Standard device",
  manual: "Manual",
  column: "Column",
  columns: "Columns",
  not_defined: "Not defined",
  customize_data_view: "Customize data view",
  customize_history_view: "Customize history view",
  ordernation: "Ordernation",
  ordination_help_text: "Click the columns or drag and drop the data to specify the order. Click at &nbsp;<i class='glyphicon glyphicon-eye-open'></i>&nbsp; to add/remove a data from the final visualization.",
  ascendent: "Ascendent",
  descendent: "Descendent",
  interval: "Interval|Intervals",
  current_value: "Current value",
  hide_show: "Hide/Show",
  sort: "Sort",
  sort_by: "sort by",
  then_by: "then by",
  sort_columns: "Ordenar colunas",
  redefine: "Redefine",
  port: "Port",
  plug_port: "Plug port",
  area_zoom: 'Area zooming',
  hints: {
    inherits_parent_process_area: {
      connector: "Enabled if this device belongs to the same process area as its connector",
      device: "Enabled if this data belongs to the same process area as its device",
      data: "Enabled if this alarm belongs to the same process area as its data",
    },
    notification_enabled: "Resource might send notification messages if enabled",
    notification: "Insert the {group} notification list (use [enter] to add new item)",
    member_email: 'Type an already registred user\'s email or send an invitation to add him to your contract',
    member_privileges: 'Define the privileges this user will have over your contract associating Process Areas and Groups',
    member_process_area: 'Process Area that he will have access to',
    member_groups: 'Group of policies the user inherit within the competent process area',
    new_group: 'Create a group to manage your contract\'s members permissions',
    group_permissions: 'Set the permissions to be granted to members of this group',
    process_area: 'Process areas improve management of registered resources allowing you to separate them in different contexts',
    maximum_number_of_devices_that_can_connect_to_the_portal: "Maximum number of devices that can connect to the portal",
    maximum_number_of_third_party_devices_that_can_connect_to_the_portal: "Maximum number of third-party (non-HI) devices that can connect to the portal",
    maximum_number_of_data_that_can_be_stored_by_the_portal: "Maximum number of data that can be stored by the portal. It defines measurements and their properties such as type and format that can be stored and read in your database in the portal. They also configure the maintenance (history) or volatility of the stored samples.",
    total_number_of_alarms_that_can_be_configured_on_the_portal: "Total number of alarms that can be configured on the portal",
    reserved_number_of_data_with_history: "Reserved number of data with history",
    enables_disconnection_and_alarm_notifications_by_telegram: "Enables disconnection and alarm notifications by Telegram",
    enables_access_to_the_rest_api_for_developers_to_create_their_own_solution_based_on_portal_data: "Enables access to the REST API for developers to create their own solution based on portal data",
    enables_data_value_customization_with_text_assignment: "Enables data value customization with text assignment",
    enable_connector_geolocation_map_view: "Enable connector geolocation (map view)",
    enables_remote_access: "Enables remote access. It is required for remote debugging and remote configuration. It requires <a href='https://www.hitecnologia.com.br/produtos/software/conectividade/plug-telemetria-acesso-remoto-via-portal-de-telemetria/' target=_blank>Plug Telemetria</a>",
    enables_connector_models: "Enable the use of models of connector. A connector model makes management easier once it centralizes the configuration of similar connectors",
    screen_selection: "Select a panel configuration to to visualize this equipment",
    pooling_interval: "Select the pooling interval",
    acquisition_interval: "Select the acquisition data sample rate",
    enables_custom_screens: "Enables screen customization",
    select_the_option_based_on_the_item_description: "Select the option based on the item description",
    user_timezone: "User timezone",
    date_time_format: "Configure how date and time will be displayed",
    numeric_format: "Configure how date and time will be displayed",
    access_to_configuration_form: "Access to the configuration form",
    select_the_event_that_will_trigger_actions: "Select the event that will trigger actions",
    select_the_type_and_actions_you_want_to_trigger_at_this_event: "Select the type and actions you want to trigger at this event",
    copy_and_open_anonymous: "Page URL copied!<br>Press {key} to open anonymous mode,<br>then Ctrl+V to test the page",
    set_as_default: "Set as default",
    aquisition_interval: "It defines the valid range for data acquisition. Out-of-range samples will be discarded.",
    aquisition_interval_local_data: "It defines the valid range for local data.",
    synoptic_proportion: "It defines proportinal values for evaluating aspect-ratio (width/height). In case there is a background image, you should make use of equivalent values as the proportions",
    connector_list_acl: "Access will be restricted to the items on the list. If none, no restriction will be applied",
    device_table_list_standard_panel: "The home page panel setting will be overwritten by this one if you mark this panel as the default. You can reverse this setting.",
    mqtt_topic: "This resource topic name",
    mqtt_device_topic: "This device topic name. Data topic belongs its scope",
    mqtt_qos: "Quality of MQTT service for this resource",
    mqtt_retain: "Clients of retained topics gets its first message as soon as they subscribe on it",
    screen_data_mapping: "You can dynamically assign new data set to synoptic custom controls or panel data list",
    history_type: "It defined how the samples will be recorded",
    identifier: "Unique identifier for the resource",
    click_to_open_ctrl_click_to_popup_it: "Click to show or Ctrl+Click to open it on a popup window",
    connector_data_mapping: "It allows this connector to send its data values to another connector",
    data_mapping: "You can share this data with another connector by enabling this value",
    access_mode: "Select the access policies for this data",
    data_identification: "Data identification at local data storage",
    default_interval: "Pre-defined initial period for sample filtering",
    edges_options: {
      leave_them_empty: "Samples that do not coincide with start/end of the selected period, will not be adjusted.",
      fit_in_time_window: "It fits samples out of the selected period, to the time window"
    },
    cell_interpolation: {
      leave_them_empty: "Leave empty the cell which does not have readings at the time indicated by the row",
      last_value: "It shows the last known value in cells which does not have readings",
      linear_interpolation: "It shows the linear interpolation result in cells which does not have readings"
    },
    background_image_url: "Background Image URL.",
    background_image_url_error: "Target image does not match to the background image control",
    realtime_chart: "This chart will show real time value variations",
    chart_zoom: "Enables/disables the chart zoom functionality. Zoom in/out might be triggered through a specific panel or in direct manipulation in the graph area",
    expression_for_actions: "Logical expression that which triggers a sequence of actions",
    original_panel_dimension: "Values defines the actual proportion of panel's width and height. Such result will be used to adapt the initial dimension to the panel slot within device context",
    current_panel_dimension: "Current panel dimension according to the device resolution and application window size",
    background_image: "Background Image of this panel. For better results, keep its aspect ratio according to the canvas",
    background_image_locked: "Same as the canvas size",
    background_image_unlocked: "Distinct size",
    canvas_size_locked: "Proportinal size. Height and Width depend on each other",
    canvas_size_unlocked: "Height and Width are independent values",
    tags: "Tags (or hashtags) are keywords that helps you to organize your assets for indexing and searches",
    integrated_refresh: "Values updated also by manual refresh",
    update_at_startup: "By enabling it, the period will be applied to any panel which requires data history. Otherwise, this filter must be manully applied.",
    history_disabled: "<b>Attention!</b> You have disabled the history option for this item. Old samples will be inaccessible",
    select_a_screen_connector: "The opened screen will use the assigned connector as the resource load base. By leaving it empty, the current connection remains as base",
    screen_parameters: "Inform the key value set required by the remote screen, if any",
    mobile_native_printout: "At mobile devices, the app will not generate the pdf document. The browser will do so.",
    data_collector_device: "Select the device which provides the actual data",
    device_address: "Device initial address",
    keep_screen_references_among_devices: "For devices that share same data, keeping references among them would make it easer to create virtual device screens. But you would not be able to visualize relative data at screen based on connector model",
    current_screen: "Current screen of the selected connector. <br>Whether applicable, click on the <i class='fa fa-exchange'/> button to replace it by this one.",
    resource_path: "Resource screen component identification",
    use_in_expression: "Click to make use of {item} in the expression field of this control",
    expression_warning: "Displayed value depends on the control expression result",
    add_device_as_screen_parameter: "Add device as screen parameter",
    inherit_template_settings: "Inherit from a model, will make this {item} and its resources to be automatically updated when the model is changed",
    device_selection: "Devices available for this instance. Only the selected ones will be charged.",
    memory_size: "Alocated memory for the specific memory type. It might be an number between {min} and {max}",
    vector_size: "Alocated number of positions for the specific memory type. It might be an number between {min} and {max}",
    local_data: "A Local Data of a device exists only in the context of the Telemetry portal, ie it will not be synchronized with the remote equipment. It can be used, for example, to characterize a device independently in the system interfaces.",
    fit_to_page: "Fit to page",
    notification_mode: "Defines when notification of this alarm will occur.",
    data_value_index: "Value index is required for complex data structure",
    sample_loss_warning: "Changes might result in loss of sampled values",
    save_on_tab: "If enabled, the TAB key will save changed values",
    quick_preview: "If enabled, changes will be visually available for other controls, even when yet not persisted",
    disabling_resource: "Disable Resource",
    data_to_enable_history: "Data to enable history",
    overwrite_existing_file: "A file named \"{file}\" already exists in \"${folder}\"",
    current_value_local_data: "Value of a local data",
    data_filter: "Filter the sample data set of same timestamp than the data filter ones",
    dropdown: "Click to check/uncheck an option<br/>Ctrl+Click to check only the option.<br/>Press OK button to apply changes.",
    machine_name: "Machine name ",
    machine_unit: "Unit",
    machine_default_hourly_production: "Standard production/hour ",
    machine_production_order_filtering: "Filter in order of production ",
    machine_production_order_exists: "Check this option if the machine uses the production order concept in its operation to group and identify the information.",
    machine_casualty_limit: "Losses",
    machine_unavailability_limit: "Unavailability ",
    machine_low_quality_limit: "Low quality",
    machine_low_performance_limit: "Low performance",
    machine_url: "URL Address",
    machine_url_button_text: "Button text",
    machine_url_button: "Button to open a URL outside the OnlinEE. It can be a panel, a website, etc.",
    machine_limits: "Limits used in machine calculations to define consolidated values and states.",
    header: "Header of the dashboard page",
    sidebar: "Left side bar options of the dashboard page",
    does_it_show_models_only_or_all_connectors: "Does it show models only or all connectors?",
    force_end_test: "Ends the testing period of this contract and starts charging for the service",
    use_dashboard_url: "Fill in this field with the URL path that opens the dashboardd configured in the connector",
    alarm_limit_float: "Alarm activation limit value.Fill this field with a numerical value with decimal places.Use only numbers and the separator should be the point and not the comma.",
    alarm_limit_number: "Alarm activation limit value.Fill this field with a numerical value without decimal places.Use only numbers.",
    alarm_limit_string: "Alarm activation limit value.Fill this field with a text.",
    open_machine_dashboard: "Open Dashboard of the Machine on Online",
    button_export: "Export records to CSV.You can individually select only the records you want to export or not select any to export all.",
    button_import: "Import records from a CSV file.",
    reason_list_value: "Numerical identification to be informed by the user in the process to associate the stop events with this reason. ",
    reason_list_text: "Textual identification of the stop reason that will be presented at Onlinee (Dashboard, Reports, etc.)",
    reason_list_color: "Color associated with the stop reason that will be presented at Onlinee.",
    reason_list_is_planned_stop: "Indicates if the stop is programmed (planned).The stopping time of a machine when programmed is not accounted for in the calculation of the OEE index.",
    casualty_list_value: "Numerical identification to be informed by the user in the process to associate loss events with this reason.",
    casualty_list_text: "Textual identification of the loss of loss that will be presented at Onlinee (Dashboard, Reports, etc.)",
    casualty_list_color: "Color associated with the reason for loss that will be presented on onlinee.",
    identity_embedded_app: "Indicates the identification of the data in the application/firmware of the equipment where the data is collected.",
    linked_panel_selector: "Usar Painéis de outra Tela como Base",
    editor: {
      "screen": "Edit Dashboard and Reference connector settings",
      "layout": "Edit Dashboard layouts and panel settings",
      "restore": "Restore to the latest published dashboard",
      "preview": "Preview Dashboard",
      "publish": "Save new Dashboard version."
    },
    remove_all: "Remove all|Remove all",
    showing_n_of_n_items: "Displaying {items} of {total} items",
    reference_connector: "It defines the default database for this screen.\nIf you change this field after publishing the dashboard, you can use the data replacement (maintenance) function to reconfigure your dashboard",
    data_replacement: "This operation will replace all occurrences of the Source data for the Destination data on this dashboard (screen), including controls and panels",
    is_hi_device: "Does it runs firmware developed by the HI Tecnologia?",
    cleaning_due_date: "Items that have been in trash more than {days} days will be automatically deleted."
  },
  placeholders: {
    tags: "Ex: project1 customer",
    search: "Ex: connector-name, #tag, etc"
  },
  titles: {
    reorder_table: "Click to reorder the table",
    parent_selection_locked: "Unlock ancestry change",
    parent_selection_unlocked: "Lock ancestry change",
    select_data: "Select data",
    select_all_data: "Select all data",
    add_all_data: "Add all data",
    add_selected: "Add selected",
    tab_selection: "Visualize device data",
    tab_editor: "Write data value",
    data_not_allows_writing: "This data does not allow writing",
    data_allows_writing: "This data allows writing",
    reset_value: "Reset value",
    insert_data_value: "Insert the data value",
    change_data_value: "Change value",
    access_alarms_history: 'Access Alarm History',
    insert_search_text: 'Insert search text',
    pending_writing: 'Pending value writing',
    insert_date_interval: 'Insert an interval',
    select_date_interval: 'Select an interval',
    filter_by_data: 'Filter by data',
    filter_by: 'Filter by',
    data_filter: "Data filter",
    view_table: 'View table',
    view_graph: 'View graph',
    view_history: 'View history',
    view_config: 'View configuration',
    previous_page: 'Previous page',
    first_page: 'First page',
    last_page: 'Last page',
    next: "Next",
    next_page: 'Next page',
    load_next_page: 'Load next page',
    you_dont_have_permission: 'You don\'t have permission to edit it',
    remove_process_area: 'Remove Process Area',
    select: 'Select',
    select_all: 'Select all',
    clear_selection: 'Clear selection',
    cancel_selection: 'Cancel selection',
    group_name: 'Group name',
    group_description: 'Group description',
    group_is_system: 'System group cannot be edited',
    access_dashboard: 'Access equipment dashboard',
    showing_search_results: 'Showing search results. Clear the filter to show all items.',
    panel_access_by_process_area: 'Only users with access to some of the Process Areas below will see this panel',
    button_access_by_process_area: 'Only users with access to some of the Process Areas below will see this button',
    button_interaction_by_process_area: 'Only users with access to some of the Process Areas below will be able to interact with this button',
    control_access_by_process_area: 'Only users with access to some of the Process Areas below will see this control',
    control_interaction_by_process_area: 'Only users with access to some of the Process Areas below will be able to interact with this control',
    access_mode: "Access Mode",
    layout: {
      large_panel: "Large Panel",
      two_panels: "Two panels",
      three_inline_panels: "Three inline panels",
      large_top_panel_two_panels: "Large top panel and two panels",
      two_panels_large_bottom_panel: "Two panels and large bottom panel",
      long_left_panel_two_panel: "Long left panel and two panels",
      two_panels_long_right_panels: "Two panels and long right panel",
      four_panels: "Four panels",
    },
    calendar: "Calendar",
    tabs: "Tabs",
    filters: "Filters",
    download: "Download",
    download_as_CSV: "CSV file",
    download_as_XLS: "XLS file",
    download_up_to_365_days: "Baixar até 365 dias",
    default_dashboard_for_user: "Tab shown by default when accessing Portal Home page (only for this user).",
    default_dashboard_for_contract: "Tab shown by default when accessing Portal Home page (valid for all users of this contract that do not have a set preference).",
    resource_chart_hint: 'Usage/Limit ratio. The chart shows the usage percentage.',
    select_process_areas_for_all_users: 'Select Process Areas to associate with all group\'s users at once',
    select_groups_for_all_users: 'Select Permission Groups to associate with all area\'s users at once',
    add_process_areas_to_users: 'Add Process Areas to all users',
    add_groups_to_users: 'Add Permission Groups to all users',
    print: "Printout",
    preview: "Preview",
    set_screen_as_default: "Set this screen as the connector's default?",
    cell_with_data_reference: "Cell with data reference|Cells with data reference",
    there_are_no_named_data_controls: "There are no named controls",
    there_are_no_data_cell: "There are no data cells",
    remove_panel: "Remove this panel.\nCtrl+Click for layout resize",
    gallery_panels: "Gallery Panels",
    gallery_layouts: "Gallery Layouts",
    base_screen: "Base Screen",
    screen_panels: "Screen Panels",
    base_screen_panels: "Base screen panels",
    screen_layouts: "Screen Layouts",
    remote_panel: "Remote panel",
    sync_enabled: "Sync enabled",
    source_screen: "Source screen",
    data_collector_device: "Data collector device",
    consume_forecast: "Usage: {devices} devices and {data} data from your quota",
    resource_path: "Resource path",
    available_list: "Available {list}",
    number_of: "Number of {list}",
    expression_created: "Expression created",
    data_consumption: "Data consumption",
    forecast_data_consumption: "Forecast data consumption",
    invitation_info: "It will be created an inactive contract member. You might pre-define its privileges",
    add_your_first: "Add your first {item}",
    local_data: "Local data",
    current_value_local_data: "Value",
    fit_to_page: "Fit to page",
    form: "Form",
    data_value_index: "Data value Index",
    index: "Index",
    vector_size: "Vector size",
    element: "Element|Elements",
    quick_preview: "Quick preview",
    save_on_tab: "Save on TAB",
    data_to_enable_history: "Data to enable history",
    overwrite_existing_file: "Overwrite existing file?",
    value_at: "Value at",
    notification_mode: 'Notification Mode',
    components: "Components",
    visible_components: "Visible components",
    delay_before_persist: "Delay before persist",
    machine_name: "Machine name ",
    machine_unit: "Unit",
    machine_default_hourly_production: "Standard production/hour ",
    machine_production_order_filtering: "Filter in order of production ",
    machine_production_order_exists: "Uses production order?",
    machine_casualty_limit: "Losses",
    machine_unavailability_limit: "Unavailability ",
    machine_low_quality_limit: "Low quality",
    machine_low_performance_limit: "Low performance",
    machine_url: "URL Address ",
    machine_url_button_text: "Button text ",
    header: "Header",
    sidebar: "Sidebar",
    editing_not_allowed: "Editing not allowed",
    force_end_test: "End testing period and hire",
    use_dashboard_url: "Use the connector's dashboard",
    identity_embedded_app: "Identity Embedded App",
    trash_can: "Trashcan"
  },
  placeholders: {
    tags: "Example: project1 acme",
    search: "Eg: connector-name, #hashtag, etc"
  },
  keep_me_connected: "Keep me connected",
  details: "Details",
  page: {
    sign_in: "Sign in",
    sign_up: "Sign up",
    password_reset: "Password Reset",
    account_activation: "Account Activation",
    user_dashboard: "User dashboard",
    connectors: "Connectors",
    devices: "Devices",
    data: "Data",
    alarms: "Alarms",
    control_access: "Control access",
    user_profile: "User profile",
    user_plan: "User Plan",
    event_history: "Event History",
    equipment_dashboard: "Equipment dashboard",
    data_value_editor: "Data value editor",
    alarm_notification_editor: "Alarm notification editor",
    dashboard_editor: 'Dashboard editor',
    custom: 'Custom tab',
    settings: 'Settings',
    screens: "Screens",
    changelog: "Changelog",
    onlinee_config: "OnlinEE Config"
  },
  pooling_interval: "Pooling interval",
  pooling_interval_ui: "User interface pooling interval ",
  manual_pooling_interval: "Interval between manual pooling ",
  manual_pooling_interval_ui: "Mininimum interval between manual pooling ",
  acquisition_interval: "Acquisition interval",
  define_this_connector_as_model: "Define this connector as template",
  inherit_template_settings: "Inherit template settings",
  hardware_model: "Hardware model",
  connector_models: "Connector models",
  models: "Models",
  connectors: "Connectors",
  model_based_connectors: "Model based connectors",
  apply_changes_to_instances: "Apply changes to instances",
  do_not_apply_changes_to_instances: "Do not apply changes to instances",
  apply_changes_to_instances_alert: "This option will cause existing configurations to be overwritten by those described in this model",
  visible: "Visible",
  invisible: "Invisible",
  selected: "Selected",
  n_item_selected: "{n} item selected|{n} items selected",
  removal_model_message: "By removing this model all based resources will be detached",
  new_instances_limit_exceeded: "* new instances limit exceeded.",
  contract_state_alerts: {
    contract_requires_attention: "O contrato acessado requer atenção.",
    please_contact_admin: "Please, contact contract owner for details",
    running_out_of_resources: "Attention, not enough balance. Some features will be disabled",
    balance_is_not_enough: "Payment not confirmed. Contract disabled",
    test_ending: "Your test period is ending. Please checkout your plan soon to continue using our platform.",
    not_collecting_data: "Data is not being collectected. Please contact our comercial department."
  },
  screen: "Screen | Screens",
  panel: 'Panel | Panels',
  top: 'Top',
  middle: "Centro",
  bottom: 'Bottom',
  left: 'Left',
  right: 'Right',
  center: "Center",
  width: 'Width',
  height: 'Height',
  image: 'Image',
  label: 'Label',
  padding: "Padding",
  url: 'URL',
  eg: 'e.g. | E.g.',
  title: "Title",
  subtitle: "Subtitle",
  screen_editor: "Screen editor",
  this_screen_cannot_be_overwritten: "This screen cannot be overwritten. By continuing you agree to create a new screen.",
  publish: "Publish",
  publish_new_version: "Publicar a new version",
  last_version: "last version",
  dimensions: "Dimensions",
  edit_panel: "Edit this painel",
  edit_other_panels: "Edit this painel and other panels",
  new_line: "New line",
  synoptic: {
    remove_image: 'Remove image',
    properties: 'Properties',
    controls: 'Controls',
    available_controls: "Available controls",
    insert_label: 'Insert a label',
    data_value: 'Data value',
    background_color: 'Background color',
    background_image: 'Background image',
    text_color: 'Text color or foreground color',
    colors: "Colors",
    error_state: "Error",
    focus_state: "Focus",
    pending_state: "Pending",
    new_image: 'New image',
    new_text: "New text",
    image_list: 'Image list',
    text_list: 'Text list',
    data_name: 'Data name',
    data_not_found: 'Data not found',
    reference_connector: 'Reference connector',
    border: 'Border',
    grid_line: "Grid Line",
    border_radius: 'Round border',
    solid: "Solid",
    dashed: "Dashed",
    dotted: "Dotted",
    double: "Double",
    groove: "Groove",
    ridge: "Ridge",
    inset: "Inset",
    outset: "Outset",
    none: "None",
    text_align: 'Alignment',
    font: 'Font',
    select_from_library: 'Select from library',
    select_image: 'Select image',
    rotation: 'Rotation',
    lock: "Lock",
    unlock: "Unlock",
    panel_locked: 'Panel locked',
    enable_select_mode: 'Enable restricted movement',
    disable_select_mode: 'Disable restricted movement',
    panels: {
      basic_equipment_info: 'Basic equipment info',
      basic_equipment_alarm: 'Basic equipment alarm',
      maintenance: 'Maintenance',
      synoptic: 'Synoptic',
      equipment_history: 'Equipment history',
      alarms: 'Alarms',
      history_panel: "History Panel",
      blank_panel: "Blank panel",
      devices: "Devices",
      event_viewer: "Event viewer",
      notification: "Notification editor",
      equipment_runtime_data_editor: "Data editor",
      equipment_toolbar_panel: "Toolbar",
      history_chart_panel: "History chart",
      chart_panel: "Chart Panel",
      dashboard_table_panel: "Table",
      rich_text_panel: "Rich text panel",
      image_panel: "Image panel",
      equipment_status_panel: "Equipment status panel",
      custom_device_list: "Custom device list - tabular",
      equipment_card_panel: "Connector card list - cards",
      equipment_search_panel: "Equipment search panel",
      title: "Panels",
      custom_panel: "Custom Panel"
    },
    events: {
      on: "On",
      click: "Click",
      released: "Release",
      change: "Change",
      execute_action: "Execute action|execute_actions",
      dataChange: "Data value change",
      select_an_event: "Selecione um evento",
      select_an_action: "Selecione uma ação",
      add_an_action: "Add an action",
      add_an_event: "Add an event",
      activated: "Activated",
      deactivated: "Deactivated",
      actions: {
        page: "Page",
        screen: "Screen",
        print: "Print",
        tag: "Data",
        user: "User",
        panel: "Panel",
        style: "Style",
        content: "Content",
        css: "CSS",
        full_screen: "Full screen",
        page_open: "Open page",
        page_tab: "Open page (tab)",
        page_window: "Open page (window)",
        page_modal: "Open page (modal)",
        page_home: "Open home page",
        page_previous: "Go to previous page",
        page_next: "Go to next page",
        screen_open: "Open screen",
        screen_tab: "Open screen (tab)",
        screen_window: "Open screen (window)",
        screen_modal: "Open screen (modal)",
        screen_popup: "Open screen (Popup)",
        print_preview: "Printout preview",
        native_print: "Native print",
        panel_open: "Open panel",
        panel_modal: "Open panel (modal)",
        panel_expand: "Expand panel",
        panel_full_screen: "Full screen",
        script_function: "Run Javascript function",
        tag_set_constant: "Set value to data",
        tag_invert: "Invert data value",
        set_next: "Set next value to data",
        user_logout: "Logout",
        user_profile: "Open user profile",
        user_manage: "Open Access Control",
        color: "Color",
        background_color: "Background color",
        hide: "Hide",
        show: "Show",
        text: "Text",
        image: "Image",
        disable: "Disable",
        form: "Form",
        submit: "Submit",
        reset: "Reset",
        animation: "Animation",
        onlinee: "OnlinEE",
        onlinee_home: "Home page",
        onlinee_machine_dashboard: "Machine Dashboard",
      },
      process_area: 'Restricts the execution of the action to users with access to the defined Process Area.'
    },
    controls: {
      SynopticStaticLabel: "Static texts",
      SynopticFormattedDisplayValue: "Display data values",
      SynopticImage: "Representation of an image",
      SynopticStatusLabel: "List of texts associated with data values",
      SynopticStatusIcon: "List of images associated with data values",
      SynopticDataValueInput: "Editing data values",
      SynopticDataValueSlider: "Editing data values via a checkbox",
      SynopticDataValueSelect: "Editing data values via a check box",
      SynopticDataValueCheckbox: "Editing data values using a check box",
      SynopticDataValueToggle: "Editing data values via a position button (ON and OFF)",
      SynopticButton: "Action execution",
      SynopticProgressBar: "Representation of data values in a progress bar (vertical or horizontal)",
      SynopticSimpleTable: "Configurable table",
      SynopticGauge: "Representation of data values in a gauge",
      SynopticChart: "Representing data values in a graph",
      SynopticCustomControl: "External page embedding",
    },
    uses_page_default_color: 'Uses page default color',
    process_area: 'Restricts control view to users with access to the selected Process Area.',
    just_one_may_be_placed: "Just one may be placed"
  },
  custom_screens: "Custom screens",
  failed_to_load_panels: "Failed to load panels",
  failed_to_update_ref_map: "Failed to update reference connector",
  select_ref_connector: 'Select reference connector',
  unsaved_changes: 'Unsaved changes',
  no_unsaved_changes: 'No changes to publish',
  publish_changes: 'Publish changes',
  this_screen_cannot_be_removed: 'This screen cannot be removed',
  release_notes: 'Release notes',
  release_notes_placeholder: "Describe here what's new in this version",
  data_source: "Data source",
  content: "Content",
  global: "Global",
  control: "Control",
  list: "List",
  local: "Local",
  this_will_reset_all_of_your_unpushed_changes: "This will reset all of your unpushed changes",
  upload: 'Upload',
  uploading: 'Uploading',
  upload_image: 'Upload image',
  library: 'Library',
  no_images: 'No images',
  select: 'Select',
  undo_changes: 'Undo changes',
  public: 'Public',
  create: 'Create',
  rename: 'Rename',
  creating: 'Creating',
  add: 'Add',
  ref_map_required: 'Select the reference connector to continue.',
  publish_screen_before_edit: 'Publish the screen to edit this panel.',
  no_template: 'Screen file unavailable.',
  select_a_screen_to_edit: 'Select a screen to edit',
  select_a_screen: 'Select a screen',
  after_save_action: "After save action",
  ref_map_not_found: "Reference connector not found for this screen. | Go to the screen editor to set one.",
  error: 'Error',
  version: "Version",
  versions: "Versions",
  restore: "Restore",
  restore_screen: 'Restore last published version of the screen',
  panel_properties: 'Panel properties',
  panel_content_properties: "Panel content properties",
  new_panel: 'New blank panel',
  publish_screen: 'Publish screen',
  close_editing: 'Exit editing mode',
  undo_action: 'Desfazer ação',
  redo_action: 'Refazer ação',
  view: "View",
  expand_collapse_panel: "Expand/Collapse Panel",
  undo: "Undo",
  redo: "Redo",
  not_persisted: "Not persisted",
  test_value: "Test value",
  equipment_runtime_data_editor: "Data editor",
  export: "Export",
  import: "Import",
  import_from_contract: "Import from contract",
  limits: "Limits",
  minimum: "Minimum",
  maximum: "Maximum",
  increment: "Increment",
  undefined: "undefined",
  constant: "Constant",
  from_data: "From data",
  command_status: "Command Status",
  control_status: "Control Status",
  has_pending_commands: "has pending commands",
  command_sent: 'Command sent',
  data_value_updated: 'Data value updated',
  top_left: "top_left",
  top_right: "top_right",
  top_center: "top_center",
  middle_left: "middle_left",
  middle_right: "middle_right",
  middle_center: "middle_center",
  bottom_left: "bottom_left",
  bottom_right: "bottom_right",
  bottom_center: "bottom_center",
  remove_selected_ones: 'Remove selected ones',
  proportional: 'Proportional',
  proportion: "Proportion|Proportions",
  unlinked: 'Unlinked',
  equal: 'Equal',
  review_the_screen_controls_associated_with_this_item: "After saving, review the screen controls associated with this item",
  screen_importation: "screen_importation",
  destination: "Destination",
  action: "Action",
  key: "Key | Keys",
  insert_key: "Insert a key",
  insert_value: "Insert a value",
  appearance: "Appearance",
  events: "Events",
  controls: 'Controls',
  properties: 'Properties',
  available_controls: "Available controls",
  same_revision_code_error: "Version already used in last publication",
  old_version_alert: "You are editing an outdated version of this screen {version}. Publishing it may overwrite other changes.",
  download_failed: 'Failed to download',
  executed: "Executed",
  invalid_value: "Invalid value",
  invalid_css_value: 'Invalid value. Use "px" or "%".',
  authenticated: "Authenticated",
  sent: "Sent",
  update_page: "Update this page",
  show: 'Show',
  info: "Info",
  hint: "Dica",
  h1: 'H1',
  h2: 'H2',
  h3: 'H3',
  h4: 'H4',
  h5: 'H5',
  h6: 'H6',
  rich_text: {
    bold: 'Bold (Ctrl+B)',
    italic: 'Italic (Ctrl+I)',
    underline: 'Underline (Ctrl+U)',
    strikethrough: 'Strikethrough (Ctrl+D)',
    heading: 'Heading | Heading {level} (Ctrl+Shift+{level})',
    blockquote: 'Blockquote (Ctrl+>)',
    horizontal_rule: 'Horizontal rule',
    code: 'Code',
    bullet_list: 'Bullet list (Ctrl+Shift+8)',
    ordered_list: 'Ordered list',
    undo: 'Undo (Ctrl+Z)',
    redo: 'Redo (Ctrl+Shift+Z)',
    simple_text: 'Simple text',
    table: 'Table',
    delete_table: 'Delete table',
    delete_row: 'Delete row',
    delete_column: 'Delete column',
    reset_row_content: "Reset row content",
    reset_row_style: "Reset row style",
    reset_column: "Reset column style",
    reset_cell: "Reset cell style",
    add_row_before: 'Add row before',
    add_row_after: 'Add row after',
    add_column: "Add column",
    add_column_before: 'Add column before',
    add_column_after: 'Add column after',
    toggle_cell_merge: 'Toggle cell merge',
    icon: 'Icon',
    advanced_editing: 'Advanced editing | Simple editing',
    legend: "Legend",
    cell_properties: "Cell properties",
    cell_content: "Cell content"
  },
  color_pattern: "Color pattern",
  single: "Single",
  gradient: "Gradient",
  solid: "Solid",
  piled: "Piled",
  equipment_history: 'Equipment history',
  disable_password_change: "Disable password change",
  canvas_properties: "Canvas properties",
  unacknowledgement: "Unacknowledgement",
  updated_at: "Updated at",
  deleted_at: "Deleted at",
  load_more: 'Load more',
  more: "More",
  source: "Source",
  alias: 'Alias',
  color: 'Color | Colors',
  toolbar: "Toolbar",
  new_button: "New button",
  button: "Button | Buttons",
  apply_to_other_panels: "Apply to other panels",
  up_to: "Up to",
  dataset_will_be_available_for_download: "Dataset will be available for download",
  there_is_already_an_active_download_task: "There is already an active download task",
  days: "Days",
  retry: "Retry",
  request_status: "Request Status",
  last_request: "Last request",
  available: "Available",
  not_available: "Not available",
  background: "Background",
  both: "Both",
  icon: "Icon",
  preferences: "Preferences",
  date_time: "date time",
  numeric: "numeric",
  date_time_format: "Date time format",
  numeric_format: "Numeric format",
  automatic: "Automatic",
  chart: "Chart",
  y_axle: "Y Axle",
  x_axle: "X Axle",
  last_release: 'Last release',
  news_from_release: "What's new in version {version}",
  portal_updated: "Portal updated! Checkout what's new in version {version}",
  see: 'See',
  logical_expression: "Logical expression",
  new_logical_expression: "New logical expression",
  always: "Always",
  always_visible: "Always visible",
  hidden: "Hidden",
  never: "Never",
  scroll_bars: "Scroll bars",
  actions: "Actions",
  if: "If",
  then: "Then",
  expression: "Expression",
  add_expression: "Add expression",
  remove_expression: "Remove expression",
  edit_expression: "Edit expression",
  year: "Year",
  yearly: "Yearly",
  month: "Month",
  monthly: "Monthly",
  different_system_timezone_detected: "Different system timezone detected",
  screen_process_area: 'Restricts screen view to users with access to the selected Process Area.',
  data_amount: "Data amount",
  how_often_do_you_want_to_be_charged: "How often do you want to be charged?",
  save_on_the_annual_plan: "Save 16% on the annual plan",
  buy: "Buy",
  please_accept_the_terms_and_conditions: "Please, accept the terms and conditions",
  start: "Start",
  stop: "Stop",
  configure: "Configure",
  orientation: "Orientation",
  vertical: "Vertical",
  horizontal: "Horizontal",
  removal_pending_command: "Removal pending command",
  created_at: "Created at",
  specific_properties: "Specific properties",
  group_permissions: {
    "EQUIPAMENTO-CADASTRO": "Allows the registration of connectors",
    "EQUIPAMENTO-ACESSO": "Allows the visualization of connectors (List of connectors)",
    "EQUIPAMENTO-ESCRITA": "Allows changes to registered connectors",
    "ESTAÇÃO-ESCRITA": "Allows changes to devices",
    "ESTAÇÃO-ACESSO": "Allows the visualization of devices (List of devices and Home)",
    "ESTAÇÃO-CADASTRO": "Allows the registration of devices",
    "DADO-CADASTRO": "Allows the registration of data",
    "DADO-ACESSO": "Allows the visualization of data",
    "DADO-ESCRITA": "Allows changes to data",
    "ALARME-CADASTRO": "Allows the registration of alarms",
    "ALARME-ACESSO": "Allows the visualization of alarms",
    "ALARME-RECONHECIMENTO": "Allows active alarms to be recognized",
    "ÁREA DE PROCESSO-CADASTRO": "Allows the registration of Process Areas",
    "ÁREA DE PROCESSO-ACESSO": "Allows the visualization of Process Areas",
    "ÁREA DE PROCESSO-ESCRITA": "Allows changes to Process Areas",
    "GRUPO DE USUÁRIOS-CADASTRO": "Allows the registration of Users Groups",
    "GRUPO DE USUÁRIOS-ACESSO": "Allows the visualization of Users Groups",
    "GRUPO DE USUÁRIOS-ESCRITA": "Allows changes to Users Groups",
    "MEMBRO DE CONTRATO-CADASTRO": "Allows the addition of Members to the contract",
    "MEMBRO DE CONTRATO-ACESSO": "Allows the visualization of Members from the contract",
    "MEMBRO DE CONTRATO-ESCRITA": "Allows changes to privileges of Members from the contract",
    "CONTRATO-ACESSO": "Allows access to the contract",
    "CONTRATO-ESCRITA": "Allows changes to the contract",
    "TOKEN-ACESSO-REMOTO-CADASTRO": "Allows the creation of remote access Tokens",
    "TOKEN-ACESSO-REMOTO-ACESSO": "Allows the visualization of created remote access Tokens",
    "TOKEN-ACESSO-REMOTO-ESCRITA": "Allows changes to created remote access Tokens",
    "LISTA DE TEXTO - CADASTRO": "Allows the registration of Text Lists",
    "LISTA DE TEXTO - ACESSO": "Allows access to Text Lists",
    "LISTA DE TEXTO - ESCRITA": "Allows changes to Text Lists",
    "NOTIFICAÇÕES - CADASTRO": "Allows the registration of Notifications",
    "NOTIFICAÇÕES - ACESSO": "Allows access to the Notifications",
    "NOTIFICAÇÕES - ESCRITA": "Allows changes to the Notifications",
    "HISTORICO-DADO-ACESSO": "Allows the visualization of data history",
    "HISTORICO-SISTEMA-ACESSO": "Allows the visualization of system events history",
    "HISTORICO-ALARME-ACESSO": "Allows the visualization of alarm history",
    "SINOTICO-ACESSO": "Allows the use of synoptic panels",
    "MANUTENCAO-ACESSO": "Allows access to the maintenance area",
    "TELA-CUSTOMIZADA-ESCRITA": "Allows changes to custom screens",
    "TELA-CUSTOMIZADA-ACESSO": "Allows access to custom screens",
    "TELA-CUSTOMIZADA-CADASTRO": "Allows the registration of custom screens",
  },
  of: 'of',
  nothing_here: 'Nothing here',
  no_connector_found: 'No connector was found to show in the Map',
  null: 'Null',
  cell: "Cell",
  default_value: "Default Value",
  data_series: "Data series",
  serie: "Serie",
  show_symbol: "Show Symbol",
  smooth: "Smooth",
  animation: "Animation",
  add_serie: "Add serie",
  sin: "Sinusoidal",
  triangle: "Triangle",
  square: "Square",
  line: "Line",
  bar: "Bar",
  pie: "Pie",
  connector_property: "Connector's property",
  device_property: "Device's property",
  text_search: "Text search",
  connector_access: "Connector access",
  redirect_url: "Redirect URL",
  access_timeout: "Access Timeout",
  filename: "Filename",
  percentage: "Percentage",
  multiple_levels: "Multiple levels?",
  bar_color: "Standard bar's color",
  show_marker: "Show marker",
  dash: "Dash",
  custumize_view: 'Custumize view',
  wave_form: 'Wave form',
  word_wrap: "Word wrap",
  due_date: "Due date",
  table: "Table",
  style: "Style",
  bordered: "Bordered",
  condensed: "Condensed",
  striped: "striped",
  hover: "Mouse over",
  size: "Size",
  no_result_found: 'No results found to ',
  type_to_search: 'Type to search',
  aquisition_interval: "Valid range for data acquisition",
  aquisition_interval_local_data: "Valid range for local data",
  system: "System",
  simulation: "Simulation",
  simulator: "Simulator",
  topic: "Topic",
  user_manual: "User Manual",
  toggle_dropdown: 'Toggle dropdown',
  engine_version: 'Engine version',
  dataset: "Data set",
  target_cell: "Target cell",
  row_number: "Row number",
  missing_values: "Missing values",
  empty_cells: "Empty cells",
  last_value: "Last value",
  linear_interpolation: "Linear Interpolation",
  standard: "Standard",
  no_notifications: 'All clean for now.',
  see_all: "See all",
  see_more: "See more",
  you_have_unread_notifications: 'You have 1 unread notification | You have {count} unread notifications',
  last_notification: 'Last notification | Last notifications',
  commitment: "Commitment",
  default_dashboard_tab: 'Default contract dashboard tab',
  create_new_tab: {
    title: 'Create new tab',
    description: 'Add a new contract dashboard view using an existent Screen.',
    title_hint: "It'll be the screen name, by default.",
  },
  optional: 'Optional',
  removing_tabs: 'Removing tabs:',
  changes_will_be_performed_after_saving: "The changes will only be performed once clicked on \"{save}\".",
  i_know: 'I know',
  open: 'Open',
  openned: "Openned",
  mobile: "Mobile",
  mobile_native_printout: "Mobile: native printout",
  company: "Company",
  send_invitation: "Send invitation",
  invitation_sent: "Invitation sent",
  no_screens_available: 'No screens available',
  hour: "Hour|Hours",
  second: "Second | Seconds",
  minute: "Minute | Minutes",
  millisecond: "Millisecond | Milliseconds",
  day: "Day | Days",
  duration: "Duration",
  default_interval: "Default Interval",
  samples_out_of_boundaries: "Samples out of boundaries",
  leave_them_empty: "Leave them empty",
  fit_in_time_window: "Fit in time window",
  mqtt_parameters: "MQTT Parameters",
  secure_port: "Secure port",
  authenticated_port: "Authentication Port",
  custom_page: "Custom page",
  new_field: "New field",
  configured_items: "Configured items",
  required: "Required",
  restrictions: "Restrictions",
  access_key: "Access Key|Access Keys",
  create_a_token: "Create a token",
  state_indicators: "State indicators",
  pagination: "Pagination",
  search_by_text: "Search enabled",
  select_control_to_execute_action: 'Select a control to execute this action.',
  this_panel_is_set_as_default: "This panel is set as the default",
  footer: "Footer",
  card_configuration: "Card configuration",
  dynamic_color: "Dynamic color",
  dynamic_background_color: "Background dynamic color?",
  arc: "Arc",
  pointer: "Pointer",
  rays: "Rays",
  scale: "Scale",
  mqtt_qos: "MQTT QoS",
  mqtt_retain: "MQTT Retain",
  mqtt_topic: "MQTT Topic",
  arc_and_scale: "Arc and Scale",
  split: "Split",
  column_title: "Título da coluna",
  not_applicable: "Not applicable",
  at_most_once: "At most once",
  at_least_once: "At least once",
  exactly_once: "Exactly once",
  general: 'General',
  time_window: 'Time window',
  time_window_desc: `
    Maximum time interval between the first and last values shown on the graph.
    <br><em>PS: values inside this period will still be discarded if the total surpasses 100.`,
  trailing_value: 'Trailing value',
  trailing_value_desc: 'Insert the last registered value in the beginning of the chart.',
  refresh_interval: 'Refresh interval',
  refresh_interval_desc: 'Time between chart updates.',
  secondary: "Secondary",
  customizable_controls: "Customizable controls",
  waiting: "Waiting",
  history_type: "history_type",
  keep_selected: "Keep selected",
  custom_format: "Custom Format",
  customized: "Customized",
  position: "Position",
  unamed: 'Unamed',
  aggregate: "Aggregate",
  confirmation: "Confirmation",
  require_confirmation: "Require Confirmation?",
  alert_icons: {
    warning: "Warning",
    info: "Info",
    error: "Error",
    success: "Success",
    none: "None"
  },
  channel: "Channel|Channels",
  daily: "Daily",
  telegram: "Telegram",
  whatsapp: "Whatsapp",
  sms: "SMS",
  identifier: "Identifier",
  save_on_tab: "Save on TAB",
  connector_standard: "Connector Standard",
  editor_device_list: "Editor device list",
  expression_editor: "Expression editor",
  javascript_editor: "Javascript editor",
  clear: 'Clear',
  please_wait: "Please, wait{timeout}...",
  please_wait_no_timeout: "Please, wait...",
  token_not_found: "Token not found",
  invalid_token: "Invalid or expired token. Request a new one",
  visibility: "Visibility",
  no_process_area_selected: 'No Area selected',
  no_process_area_available: 'Process Areas unavailable',
  restrict_view: 'Restringir visualização',
  restrict_interaction: 'Restringir interação',
  certificate: "Certificate",
  item_copied: "Item copied!",
  action_not_allowed: "Action not allowed",
  there_are_invalid_panels_on_this_screen: "There are invalid panels on this_screen",
  screen_name: "Screen name",
  restore_previous_versions: "Restore previous versions",
  on_success_navigate_to: "On success navigate to {page}",
  overwrite_draft: "Overwrite draft",
  new_draft: "New draft",
  user_identification: "User identification",
  version_older_than_the_latest: "%s version(s) older than the latest",
  latest_version: "Latest version",
  realtime_update: "Real time update",
  no_connection: 'Connection failed. Check your Internet and try again.',
  unknown_login_error: 'An error ocurred. Try again or contact the support.',
  rate_limit: 'Too many request were made in a short time period. Trying again in {param} second... | Too many request were made in a short time period. Trying again in {param} seconds...',
  login_attempt_limit: 'Login tries limit exceeded. Try again. | Login tries limit exceeded. Try again in {param} minute. | Login tries limit exceeded. Try again in {param} minutes',
  data_format: "Data format",
  style_and_format_copy: "Style and Format copy",
  read_value: "Read value",
  no_panels_available: "No panels available",
  available_panels: "Available panels",
  available_layouts: "Available layouts",
  dashboard_panels: "Dashboard panels",
  layout: "Layout|Layouts",
  remove_layout: "Remove layout",
  add_layout: "Add layout",
  available_for_mapping: "Available for mapping",
  data_mirroring: "Data Mirroring",
  replace: "Replace",
  data_replacement: "Data replacement",
  data_mapping_not_available: "Data mapping not available",
  data_mapping: "Data Mapping",
  no_mapped_destination_connector: "No mapped destination connector",
  data_sharing: "Data Sharing",
  destination_connector: "Destination connector",
  not_assigned: "Not assigned",
  select_a_connector: "Select a connector",
  save_mapping: "Save mapping",
  remove_mapping: "Remove mapping",
  mapping: "Mapping",
  tab_label: {
    connector: "Connector | Connectors",
    device: "Device | Devices",
    data: "Data | Data",
    alarm: "Alarm | Alarms",
    resource: "Resource | Resources",
    consumption: "Consumption",
    custom_field: "Custom Field | Custom Fields",
    notification: "Notification | Notifications",
    access: "Access|Access",
    general: "General",
    data_exibition: "Data exibition|Data exibitions",
    tab_connector_home: "Basic connector configuration",
    tab_connector_address: "Connector location",
    tab_connector_notification: "Nofitication settings",
    tab_connector_keys: "Public and Private keys",
    tab_data_list: "Data visualization settings",
    tab_connector_data_mirroring: "Data sharing",
    tab_custom_fields: "Custom fields",
    tab_resource: "Available devices"
  },
  access_mode: {
    read_only: "Read Only",
    write_only: "Write Only",
    read_write: "Read and Write"
  },
  no_item_recorded: "No item recorded",
  password_change_needed: "Password change needed to continue to use the system",
  password_change: "Password change",
  min_password_length: "Minimum characters",
  max_password_length: "Maximum characters",
  min_numbers: "Minimum numbers",
  min_lower_characters: "Minimum lowercase characters",
  min_upper_characters: "Minimum uppercase characters",
  min_special_characters: 'Minimum special characters (e.g. "@", "_", "(", ")", etc)',
  insert_full_name: "Insert the full name",
  show_at_position: "Show at position",
  contract_settings: "Contract settings",
  reset_default_tab: "Reset to contract default ({tab})",
  default_dashboard_for_user: "Default tab for Home of user",
  default_dashboard_for_contract: "Default tab for Home of contract",
  contract_tabs: 'Contract tabs',
  card: "Card | Cards",
  available_cards: "Available cards",
  configured_cards: "Configured cards",
  no_cards_configured: "No configured cards",
  string_search: "String search",
  show_new_connector_button: "Show new connector button?",
  filter_enabled: "Filter enabled",
  progress: "Progress",
  progress_bar: "Progress Bar",
  display: "Display",
  banner: "Banner",
  field: "Field | Fields",
  not_enough_room: "Not enough room",
  showing_n_records_in_this_page: 'Showing {items} record in this page | Showing {items} records in this page',
  dashboard: "Dashboard",
  icon_label: "{icon} icon",
  usage_statistics: 'Usage Statistics',
  system_usage_statistics: 'System Usage Statistics',
  usage: 'Usage',
  amount: 'Amount',
  connectors_model_count: 'Model Connectors',
  devices_enabled_count: 'Enabled',
  devices_disabled_count: 'Disabled',
  devices_third_party_count: "Third-party",
  devices_hi_count: 'HI Tecnologia',
  data_history_enabled_count: 'With History',
  data_history_disabled_count: 'Without History',
  data_disabled_count: 'Disabled Data',
  alarms_history_enabled_count: 'With History',
  alarms_history_disabled_count: 'Without History',
  alarms_disabled_count: 'Disabled Alarms',
  alarms_count: 'Alarms',
  process_areas_count: 'Process Areas',
  user_groups_count: 'Permission Groups',
  screens_count: 'Screens',
  users_count: 'Users',
  unlimited: 'Unlimited',
  device_means_connectors_text: 'Devices ou MQTT Connectors',
  third_party: 'third-party',
  expand_details: 'Expandir detalhes',
  collapse_details: 'Colapsar detalhes',
  no_item_found: 'No {item} found',
  remove_user_from_hierarchy: 'Remove user from {hierarchy} | Remove users from {hierarchy}',
  and_many_others: 'and another one... | and {amount} others...',
  and: 'and',
  select_user_to_continue: 'Select an user to continue',
  discard_changes: 'Discard changes?',
  continue: 'Continue',
  you_have_unsaved_changes: 'You have unsaved changes. You wish to discard them?',
  dont_ask_again: 'Do not ask again',
  in_exhibition: 'showing',
  item: 'Item | Items',
  no_users_in_group: 'No users available in this group',
  no_users_in_process_area: 'No users available in this process area',
  no_process_areas: 'No Process Areas available',
  no_groups: 'No Groups available',
  select_adding_users_to_permission_group: 'Select users to add to group',
  select_adding_users_to_process_area: 'Select users to add to process area',
  select_adding_process_areas: 'Select Process Areas associated',
  select_adding_groups: 'Select Permission Groups associated',
  existing_users_already_in_permission_group: 'Registered users already in permission group',
  existing_users_already_in_process_area: 'Registered users already in process area',
  stateless_access_control_text: 'Select users to edit it or click the button to add more to the group',
  you_cant_edit_contract_owner: 'You cannot change the privileges of the contract owner.',
  associate_process_areas_with_users: 'Associate Process Areas to group\'s users',
  associate_groups_with_users: 'Associate Permission Groups to process area\'s users',
  search_group_by_fields: 'Search group by name, description or users',
  select_a_process_area: 'Select an area',
  search_process_area_by_fields: 'Search process area by name, description or users',
  hierarchy: "Hierarchy",
  features: {
    'new-access-control': {
      name: 'Privileges Management',
      description: "New tab to facilitate management of users privileges in the contract."
    }
  },
  experimental_feature: 'Experimental feature | Experimental features',
  open_documentation: 'Open manual',
  onboarding: {
    'new-access-control': {
      title: 'New tab',
      step1: 'Use the Privileges tab to define associations between Users, Permission Groups and Process Areas in an easier way.',
      step2: 'Visualize the Users divided by a clear hierarchy, either by Permission Group or by Process Area.',
      step3: 'After selecting a Permission Group, you can see all available Users algon with its Process Areas.',
      step4: 'It is possible to add one or more existing Users at once to the selected Permission Group, defining a shared access to one or more Process Area too.'
    }
  },
  skip_tour: 'Skip tour',
  next: 'Next',
  previous: 'Previous',
  finish: 'Finish',
  enable_zoom: "Enable zoom",
  variables: "Variables",
  current_panel_dimension: "Current panel dimension",
  original_panel_dimension: "Original panel dimension",
  click_to_edit: "Click to edit it",
  ctrl_click_for_more_options: "Ctrl+Click for more options",
  drag_to_move: "Click to move it",
  screen_properties: "Screen properties",
  selection: "Selection",
  delete_this_screen: "Delete this screen",
  download_this_screen: "Download this screen",
  maintenance: "Maintenance",
  show_all: "Show all",
  identification: "Identification",
  tags: "Tags",
  connector_instances: "Connector instances",
  update_at_startup: "Filter at page startup",
  preview: "Preview",
  alarm_level: {
    critical: "Critical",
    error: "Error",
    warning: "Warning",
    info: "Info",
    debug: "Debug",
    not_set: "Notset",
    low_low: "LL (Low Low)",
    low: "L (Low)",
    high: "H (High)",
    high_high: "HH (High High)",
    base_low: "Low",
    base_medium: "Medium",
    base_high: "High"
  },
  security_code: "Security Code",
  fill_security_code: "Fill in the security code.",
  fill_security_code_email: "Fill in the security code sent to your e-mail.",
  fill_security_code_app: "Fill in the security code from your two factor authentication application.",
  fill_security_code_both: "Fill in the security code from your two factor authentication application or from your e-mail.",
  security: "Security",
  two_factor_authentication_explanation: "For your security, this portal implements access with Two Factor Authentication.",
  optional_resource_configuration: "The configuration of this resource is optional.",
  required_resource_configuration: "The configuration of this resource is required.",
  two_factor_authentication: "Two Factor Authentication",
  two_factor_authentication_email: "E-mail",
  two_factor_authentication_email_description: "Use a one-time code received in your e-mail to validate your access authenticity.",
  two_factor_authentication_app: "Authentication Application",
  two_factor_authentication_app_description: "Use a code from a Authentication using Authentication Application to validate your access authenticity.",
  two_factor_authentication_use_this_qrcode: "Use this QRCode to register this portal in your Authentication Application.",
  two_factor_authentication_suggestions: "Suggested authentication applications: Google Authenticator, Authy, FreeOTP",
  summary: "Summary",
  billing_information: "Billing Information",
  invoices: "Invoices",
  tax_invoices: "Tax Invoices",
  contracted_plan: "Contracted Plan",
  data_range: "Data Range",
  periodicity: "Periodicity",
  plan_status: "Status",
  plan_value: "Plan Value",
  expires_at: "Expires At",
  free: "Free",
  company_name: "Full Name / Company Name",
  cpf_cnpj: "CPF / CNPJ",
  area_plus_phone: "Area + Phone",
  zipcode: "Zipcode",
  number: "Number",
  complement: "Complement",
  email_billing: "Billing and Tax Invoices E-mail",
  invalid_mask: "Invalid Mask",
  invoice_due_date: "Due Date",
  invoice_payment_date: "Payment Date",
  invoice_status: "Status",
  date_range: "Date Range",
  filter_by: "Filter by",
  discount: "Discount",
  bank_slip: "Bank Slip",
  invoice: "Invoice",
  tax_invoice: "Tax Invoice",
  resend_invoice_email: "Resend Invoice to E-mail",
  invoice_status_all: "All",
  invoice_status_paid: "Paid",
  invoice_status_pending: "Pending",
  invoice_status_cancelled: "Cancelled",
  invoice_status_emitted: "Emitted",
  invoice_status_invalidated: "Invalidated",
  invoice_serial_number: "Serial Number",
  invoice_resend_email_message: "This invoice's bank slip will be resend to the e-mail registered on Billing Information.",
  invoice_resend_email_success: "The invoice's bank slip was sent with success!",
  success: "Success",
  series: "Series",
  range_from: "From",
  range_to: "To",
  your_current_plan: "Your Current Plan",
  your_new_plan: "Your New Plan",
  do_contract: "Contract",
  select_plan: "Select a Plan",
  selected_plan: "Selected Plan",
  starts_with: "Starts with",
  complete_billing_information: "Complete Your Billing Information",
  finish_contracting: "Finish Contracting",
  tax_invoice_unavailable: "Tax Invoice unavailable.",
  bank_slip_unavailable: "Bank Slip unavailable. Invoice Paid/Cancelled/etc.",
  resend_invoice_email_unavailable: "Resend Invoice to E-mail unavailable. Invoice Paid/Cancelled/etc.",
  about_the_billing: "About the Billing",
  billing_details_information: "Billing information will be used to generate invoices and bank slips that will be sent to the registered email. Please fill in the details carefully.",
  no_serial_number: "No Serial Number",
  warning: "Warning",
  register_fill_all_fields: "Please, fill all fields before continue.",
  register_contract_selected_plan: "Contract selected Plan.",
  account_locked: {
    "warning": "Warning",
    "contract_requires_attention": "Current contract requires attention.",
    "please_contact_admin": "Please, contact contract owner for details",
    "test_over_title": "His test period on the Telemetry Portal ended.",
    "test_over_subtitle": "We hope the experience was great!",
    "test_over_text_1": "To continue using the portal and its resources, it is necessary to finish hiring a plan.",
    "test_over_text_2": "Click the button below to choose a plan and continue with access.",
    "payment_not_confirmed_title": "Your account is pending payment.",
    "payment_not_confirmed_subtitle": "The additional period for the payment of your plan expired at:",
    "payment_not_confirmed_text_1": "To continue using the portal and its resources, it is necessary to make the pending payment.",
    "payment_not_confirmed_text_2": "For more information, go to <a href=\"/dashboard/settings/billing\">\"Plan \"</a> option in the user menu."
  },
  print_tax_invoice: "Print Tax Invoice.",
  tax_invoice_has_no_print: "Tax Invoice has no data for printing.",
  contact_commercial_department: "Contact our commercial department.",
  forbidden_access: "Forbidden Access.",
  page_brake: "Page break",
  page_settings: "Page Settings",
  portrait: "Portrait",
  landscape: "Landscape",
  script: "Script|Scripts",
  new_screen: "New screen",
  import_connectors: "Import Connectors",
  import_devices: "Import Devices",
  import_data: "Import Data",
  import_alarms: "Import Alarms",
  success_import_message: "Imported records without errors.",
  drag_file: "Drag a file here or click to upload",
  itself: "The {name} itself.",
  groups: "Group|Groups",
  keep_screen_references_among_devices: "Keep screen references among virtual devices",
  first_select_device: "To import Data, you have to have a Device selected.",
  first_select_data: "To import Alarms, you have to have a Data selected.",
  critical_changes_detected: "Critical changes detected ({item})!",
  instance_creation_not_allowed: "This is a model connector instance. New features are not allowed to be created.",
  download_base_file_text: "Download the base CSV file",
  click_here: "clicking here",
  csv_import_file_instructions: "The CSV standard to be received is with semicolon separator without spaces.",
  remaining_characters: "Characters remaining",
  mass_remove: "Delete multiple items",
  n_records_selected: '1 record selected | {amount} records selected',
  some_selected_items_cant_be_removed: 'Some of the selected items can\'t be removed.',
  wish_to_unselect: 'You wish to unselect them automatically?',
  history_warning: 'Warning',
  history_data_limit_reached: 'Limit of records reached. Consider reducing the selected period and/or data.',
  history_alarm_limit_reached: 'Limit of records reached. Consider reducing the selected period and/or alarms.',
  notification_mode: 'Notification mode',
  minimum_height: "Minimum height",
  maximum_height: "Maximum height",
  multiple_data_write_tab_name: "Write values",
  multiple_data_write_alert_title: "No selected data to perform writing",
  multiple_data_write_alert_description: "Select at least one data to write a value.",
  alarm_trigger_condition: "Condition",
  too_many_requests: "Number of requests exceeded.Please wait a few minutes and try again.",
  login_forbidden_access: "Valid credentials, but user has no permission in any contract. Access denied.",
  login_no_contract: "Valid credentials, but user has no permission in any contract. Access denied.",
  login_no_permissions_allowed: "Valid credentials, but user has no associated permissions. Access denied.",
  login_no_contract_permissions_allowed: "Valid credentials, but user has no associated permissions to current Contract. Access denied.",
  local_data: "Local Data",
  machine: "Machine",
  add_new_machine: "Add a new machine",
  reason_list: "Reason List",
  casualty_list: "Casualty List",
  add_reason: "Add Reason",
  reason_text: "Reason Text",
  add_casualty: "Add Casualty",
  casualty_text: "Casualty Text",
  empty_list: "Empty list.",
  click_add_to_start: "Click Add to start inserting.",
  is_planned_stop: "Planned stop",
  default_url_button_text: "Open Connector's Dashboard",
  import_casualty: "Import casualty list",
  import_reason: "Import reason list",
  confirmation_pending_save_message: "There are pending confirmation changes.Save to confirm the modifications made.",
  incompatible_data_protocol_message: "There are already data on this configured connector considering another protocol. This change will result in inconsistencies in data collection. Do you want to continue anyway? The data should be reconfigured after this action.",
  memory_type_not_selected: "Type of memory of the data not filled.",
  municipal_registration: "Municipal registration",
  state_registration: "State registration",
  back_to_top: "Back to top",
  displaying_data_as: "Displaying Data by",
  identity_embedded_app: "Application Identification",
  data_name: "Date name",
  click_to_change_to: "Click to change to",
  double_click_or_drag_to_insert: "Drag or double click the item to add into {item}",
  last_activity_at: "Last activity"
};