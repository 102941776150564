import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);

const baseurl = ""; //v1  set to "" after conversion
export default class ProcessAreaService {
  /*
  make it compatible with previous version
  */
  processAreaAdapter(processArea) {
    processArea.identity_name =
      processArea.identity_name || processArea.name || "";
    return processArea;
  }

  async fetchAll(query) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}process_areas/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          var data = null;
          if (response && response.bodyText) {
            try {
              data = response.body || JSON.parse(response.bodyText) || null;
              data = (data || []).map(function(item) {
                return self.processAreaAdapter(item);
              });
              resolve(data);
              return;
            } catch (e) {
              //console.log("Could not parse the display");
            }
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve, reject) => {
      let url = `${baseurl}process_areas/`; //v1
      let request = null;
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          var data = null;
          if (response && response.bodyText) {
            try {
              data = JSON.parse(response.bodyText);
              resolve(data);
              return;
            } catch (e) {
              //console.log("Could not parse the display");
            }
          }
          resolve(null);
        },
        (response) => {
          //console.error(response);
          reject(
            response.body.detail ||
              response.body.non_field_errors?.[0] ||
              response.statusText
          );
        }
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}process_areas/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }
}
