import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import UserGroupsService from "@/services/user-groups.js";

Vue.use(VueResource);

export default class UserService {
  elapsed(di, info) {
    if (!di) {
      return new Date().getTime();
    } else {
      //console.log(((new Date()).getTime()-di)+"ms "+ (info||''));
    }
  }

  roleAdapter(role) {
    role.identity_name = role.name;
    return role;
  }

  async fetchRoles(query) {
    let self = this;

    return new Promise((resolve) => {
      //=================================
      let srv = new UserGroupsService();
      srv.fetch(query).then((response) => {
        if (response && response.length) {
          for (var i in response) {
            response[i] = this.roleAdapter(response[i]);
          }
          resolve(response);
          return;
        }
        resolve(null);
      });
    });
  }

  async fetchRole(query) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let dashboard = Vue.http.options.dashboard;
      let role_id = query.role_id;
      let url = `${dashboard}/roles/${role_id}.json`;
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          var data = null;
          if (response && response.bodyText) {
            try {
              data = JSON.parse(response.bodyText);
            } catch (e) {
              //console.log("Could not parse the display");
            }
          }
          resolve(data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }
}
